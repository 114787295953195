import React from 'react'
import { graphql } from 'gatsby'
import { withPreview } from 'gatsby-source-prismic'
import styled from 'styled-components'
import tw from 'tailwind.macro'

import VideoWithText from './components/VideoWithText'
import CareersTemplate from './template'

const isEven = (value) => value % 2 === 0

const transformComponent = (data) => {
  return {
    title: data.our_experience_videos_item_title.text,
    description: data.our_experience_videos_item_description.html,
    footNote: data.our_experience_videos_item_footnote.text,
    video: data.our_experience_videos_item_video.url,
    poster: data.poster ? data.poster.url : null,
  }
}

const TitleWrapper = styled.div`
  ${tw`
  bg-light-grey-2
  pl-3
  xl:pl-0
  -mx-6
  xl:mx-0
  pt-4
  lg:pt-6
`}
`

const Title = styled.h2`
  ${tw`
    mx-auto
    my-0
    max-w-2xl
    text-35px
    leading-none
    font-extrabold
`}
`

function OurExperience({ data, pageContext }) {
  const { prismicCareersLandingPage } = data

  if (!prismicCareersLandingPage) return null
  const cmsData = prismicCareersLandingPage.data

  return (
    <CareersTemplate path={pageContext.name} data={cmsData} title={null}>
      <div>
        <TitleWrapper>
          <Title>{cmsData.our_experience_page_title.text}</Title>
        </TitleWrapper>
        {cmsData.our_experience_page_videos.map((video, index) => (
          <VideoWithText
            {...transformComponent(video)}
            invertColumns={isEven(index)}
            useBackground={index !== 0 ? isEven(index) : false}
            className={index === 0 ? 'bg-light-grey-2' : ''}
          />
        ))}
      </div>
    </CareersTemplate>
  )
}

export default withPreview(OurExperience)

export const pageQuery = graphql`
  query CareersExperienceQuery($locale: String!) {
    prismicCareersLandingPage(lang: { eq: $locale }) {
      data {
        mantel_title {
          text
        }
        mantel_link_url {
          url
        }
        mantel_link_text {
          text
        }
        mantel_image {
          url
        }
        mantel_description {
          text
        }
        bottom_banner_link_text {
          text
        }
        bottom_banner_link_url {
          url
        }
        bottom_banner_text {
          text
        }
        navigation_items {
          navigation_item_link_text {
            text
          }
          navigation_item_link_url {
            text
          }
        }
        our_experience_page_videos {
          our_experience_videos_item_description {
            html
          }
          our_experience_videos_item_footnote {
            text
          }
          our_experience_videos_item_title {
            text
          }
          our_experience_videos_item_video {
            url
          }
          poster {
            url
          }
        }
        our_experience_page_title {
          text
        }
      }
    }
  }
`
